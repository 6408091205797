import { Pagination, PaginationItem, Select, Stack } from "@mui/material";
import React from "react";

const CallListTablePagination = React.memo(({ page, count, onChange, siblingCount, selectItems }: {
  page: number,
  count: number,
  onChange: (page: number) => void,
  siblingCount: number,
  selectItems: JSX.Element[]
}) => {
  return (
    <Stack width="100%" direction="row" justifyContent="center">
      <Pagination
        page={page}
        count={count}
        onChange={(_, page) => onChange(page)}
        siblingCount={siblingCount}
        renderItem={(item) => {
          if(item.page !== page)
            return <PaginationItem {...item}></PaginationItem>;
          return (
            <Select
              value={page}
              onChange={(event) => onChange(Number(event.target.value))}
              size="small"
              variant="standard"
              >
              { ...selectItems }
            </Select>
          )
        }}
      ></Pagination>
    </Stack>
  )
})

export default CallListTablePagination