const callResults = [
  "TOSSUP_APPOINTMENT",
  "TOSSUP_NURTURING_A",
  "TOSSUP_NURTURING_B",
  "TOSSUP_NURTURING_C",
  "TOSSUP_ABSENT",
  "FINISHED_ON_AI",
  "DENIED",
  "DENIED_ON_AI",
  "AUTOMATED",
  "NEVER_CALLED",
  "UNREACHABLE",
  "IMPOSSIBLE",
  "AI_NURTURING",
  "TOSSUP_NOT_REGISTERED",
  "SIMULTANEOUS_TOSSUP",

  "MANUAL_OUTBOUND_APPOINTMENT",
  "MANUAL_OUTBOUND_NURTURING_A",
  "MANUAL_OUTBOUND_NURTURING_B",
  "MANUAL_OUTBOUND_NURTURING_C",
  "MANUAL_OUTBOUND_ABSENT",
  "MANUAL_OUTBOUND_DENIED",
  "MANUAL_OUTBOUND_UNREACHABLE",

  "MANUAL_INBOUND_APPOINTMENT",
  "MANUAL_INBOUND_NURTURING_A",
  "MANUAL_INBOUND_NURTURING_B",
  "MANUAL_INBOUND_NURTURING_C",
  "MANUAL_INBOUND_ABSENT",
  "MANUAL_INBOUND_DENIED",
  "MANUAL_INBOUND_UNREACHABLE",

  "ERROR",
] as const;

const tossupCallResults: Array<CallResult> = [
  "TOSSUP_APPOINTMENT",
  "TOSSUP_NURTURING_A",
  "TOSSUP_NURTURING_B",
  "TOSSUP_NURTURING_C",
  "TOSSUP_ABSENT",
  "DENIED",
];

const manualOutboundCallResults = [
  "MANUAL_OUTBOUND_APPOINTMENT",
  "MANUAL_OUTBOUND_NURTURING_A",
  "MANUAL_OUTBOUND_NURTURING_B",
  "MANUAL_OUTBOUND_NURTURING_C",
  "MANUAL_OUTBOUND_ABSENT",
  "MANUAL_OUTBOUND_DENIED",
  "MANUAL_OUTBOUND_UNREACHABLE",
] as const;

const manualInboundCallResults = [
  "MANUAL_INBOUND_APPOINTMENT",
  "MANUAL_INBOUND_NURTURING_A",
  "MANUAL_INBOUND_NURTURING_B",
  "MANUAL_INBOUND_NURTURING_C",
  "MANUAL_INBOUND_ABSENT",
  "MANUAL_INBOUND_DENIED",
  "MANUAL_INBOUND_UNREACHABLE",
] as const;

const callResultLabels: {
  [key in
    | CallResult
    | ManualOutboundCallResult
    | ManualInboundCallResult]: string;
} & {
  NO_RESPONSE: string;
  TOSSUP_NURTURING: string;
} = {
  TOSSUP_APPOINTMENT: "アポ獲得",
  TOSSUP_NURTURING: "ナーチャリング", // ナーチャリングの合計
  TOSSUP_NURTURING_A: "ナーチャリングA",
  TOSSUP_NURTURING_B: "ナーチャリングB",
  TOSSUP_NURTURING_C: "ナーチャリングC",
  TOSSUP_ABSENT: "担当者不在", // トスアップされたけど社長が不在/応答なし
  FINISHED_ON_AI: "AI終話", // トスアップされず終話
  DENIED: "コールNG", // トスアップ後でコール拒絶
  DENIED_ON_AI: "AIコールNG", // AIから架電してコール拒絶
  AUTOMATED: "留守電", // AIから架電して留守番電話
  NEVER_CALLED: "未コール",
  UNREACHABLE: "応答なし", // 手動もしくはAIで架電したが応答なし
  IMPOSSIBLE: "AI対応不可",
  AI_NURTURING: "AIホットリード",
  NO_RESPONSE: "応答なし", // 応答なし＋留守電＋AI架電不可, この2つは内部でのみ区分
  SIMULTANEOUS_TOSSUP: "AI同時接続", // AIが一人に対して同時にトスアップした場合や、連続でトスアップしてしまいやむを得ず切断したコール
  TOSSUP_NOT_REGISTERED: "未登録", // トスアップ途中で相手が抜けて未登録

  MANUAL_OUTBOUND_APPOINTMENT: "手動発信_アポ獲得",
  MANUAL_OUTBOUND_NURTURING_A: "手動発信_ナーチャリングA",
  MANUAL_OUTBOUND_NURTURING_B: "手動発信_ナーチャリングB",
  MANUAL_OUTBOUND_NURTURING_C: "手動発信_ナーチャリングC",
  MANUAL_OUTBOUND_ABSENT: "手動発信_担当者不在",
  MANUAL_OUTBOUND_DENIED: "手動発信_コールNG",
  MANUAL_OUTBOUND_UNREACHABLE: "手動発信_応答なし",

  MANUAL_INBOUND_APPOINTMENT: "手動着信_アポ獲得",
  MANUAL_INBOUND_NURTURING_A: "手動着信_ナーチャリングA",
  MANUAL_INBOUND_NURTURING_B: "手動着信_ナーチャリングB",
  MANUAL_INBOUND_NURTURING_C: "手動着信_ナーチャリングC",
  MANUAL_INBOUND_ABSENT: "手動着信_担当者不在",
  MANUAL_INBOUND_DENIED: "手動着信_コールNG",
  MANUAL_INBOUND_UNREACHABLE: "手動着信_応答なし",

  ERROR: "エラー",
} as const;
const callResultColors: { [key in keyof typeof callResultLabels]?: string } = {
  TOSSUP_APPOINTMENT: "#2f78e4",
  TOSSUP_NURTURING_A: "#916fe1",
  TOSSUP_NURTURING_B: "#916fe1",
  TOSSUP_NURTURING_C: "#916fe1",
  TOSSUP_ABSENT: "#ce61ce",
  AI_NURTURING: "#f956b0",
  FINISHED_ON_AI: "#ff578b",
  IMPOSSIBLE: "#ff6a63",
  DENIED: "#ef5350",
  DENIED_ON_AI: "#ef5350",
  NO_RESPONSE: "#bebebe",
  AUTOMATED: "#e0e0e0",
  UNREACHABLE: "#e0e0e0",
  SIMULTANEOUS_TOSSUP: "#bebebe",
  TOSSUP_NOT_REGISTERED: "#bebebe",
};
type CallDirection = "OUTGOING" | "INCOMING";
type CallResult = (typeof callResults)[number];
type ManualOutboundCallResult = (typeof manualOutboundCallResults)[number];
type ManualInboundCallResult = (typeof manualInboundCallResults)[number];

type CallHistoryRecord = {
  id: string;
  companyName: string;
  createdAt: string;
  updatedAt: string;
  calledAt: string;
  callDirection: CallDirection;
  callDuration: number;
  callMemo: string;
  result: CallResult | ManualOutboundCallResult;
  nextCallDate?: Date;
  prevCallSid?: string; // 再架電のコール履歴場合、ここに再架電されたナーチャリングコールのSIDが入る
  phoneNumber: string;
  audioPath: string;
  transcriptPath: string;
  operatorId: string;
  scriptId: string;
  callListId: string;
  callListIndex: number;
  manualCall?: boolean;
  redialAttempts: number;
  phoneCallId: string;
  callTags: string[];
};

type CallHistoryTranscript = {
  content: string;
  speaker: string;
  timestamp: number;
};

export {
  callResults,
  callResultLabels,
  callResultColors,
  manualOutboundCallResults,
  manualInboundCallResults,
  tossupCallResults,
};
export type {
  CallDirection,
  CallResult,
  CallHistoryRecord,
  ManualOutboundCallResult,
  ManualInboundCallResult,
  CallHistoryTranscript,
};
