import callIncomingImage from "@/assets/call_incoming.png";
import silentAudioPath from "@/assets/silent.mp3";
import teleapoAiLogo from "@/assets/teleapo_ai_logo.png";
import LoadingBackdrop from "@/components/LoadingBackdrop";
import { DSDrawer } from "@/features/drawer/Drawer";
import { signOutCurrentUser } from "@/google/auth";
import { useAuth } from "@/hooks/useAuth";
import {
  getTwilioToken,
  setCallDevice,
  setCurrentCall,
  setTwilioCall,
} from "@/store/callSlice";
import { setSnackbar } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import { setMissedManualInboundCallOn, setUserStatus } from "@/store/userSlice";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Alert,
  Avatar,
  Button,
  Container,
  Divider,
  Link,
  Popover,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Call, Device } from "@twilio/voice-sdk";
import dayjs from "dayjs";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import "./default.css";
import { request } from "@/models/telai-backend/client";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  DoNotDisturbOutlined,
  PhoneCallbackOutlined,
  PhoneOutlined,
} from "@mui/icons-material";

interface Props {
  hideDrawer?: boolean;
  hideAppBar?: boolean;
  disableTossUp?: boolean;
  waitUserFetch?: boolean;
}

// let popupWindow: null | Window = null
// let callAudio: null | HTMLAudioElement = null

const Layout = ({ hideDrawer, hideAppBar, waitUserFetch = true }: Props) => {
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const name = useSelector((state: RootState) => state.user.loggedInUser.name);
  const twilioCall = useSelector((state: RootState) => state.call.twilioCall);
  const currentCall = useSelector(
    (state: RootState) => state.call.calls[state.call.currentCallId],
  );
  const callState = useSelector(
    (state: RootState) => state.user.loggedInUser.status,
  );
  const loadingBackdrop = useSelector((state: RootState) =>
    Object.values(state.common.loadingBackdrop).reduce(
      (prev, curr) => prev || curr,
      false,
    ),
  );
  const aiCallLoadingBackdrop = useSelector(
    (state: RootState) => state.common.aiCallLoadingBackdrop,
  );
  const snackbar = useSelector((state: RootState) => state.common.snackbar);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const callDevice = useSelector((state: RootState) => state.call.callDevice);
  const twilioToken = useSelector((state: RootState) => state.call.twilioToken);
  const missedManualInboundCallOn = useSelector(
    (state: RootState) => state.user.missedManualInboundCallOn,
  );

  // トスアップ通知許可
  const [openNotificationRequestModal, setOpenNotificationRequestModal] =
    useState<boolean>(true);

  // drawer
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { tenantId } = useParams();
  const handleSignOutClick = useCallback(() => {
    if (callState === "FREE" || callState === "AWAY") signOutCurrentUser();
  }, [callState]);
  const handleDrawerToggle = useCallback(() => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  }, [isClosing, setMobileOpen]);
  const handleDrawerClose = useCallback(() => {
    setIsClosing(true);
    setMobileOpen(false);
  }, [setIsClosing, setMobileOpen]);
  const handleDrawerTransitionEnd = useCallback(() => {
    setIsClosing(false);
  }, [setIsClosing]);

  // 受信設定
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useAuth();

  useEffect(() => {
    dispatch(getTwilioToken());
  }, [dispatch, isSignedIn]);

  // トスアップ中だが/callscreenにいない場合は/callscreenへ遷移
  useEffect(() => {
    console.log({ twilioCall, currentCall,callState});

    if (!twilioCall) return;

    if (!["IN_CALL", "IN_CALL_WRAP_UP"].includes(callState)) return;

    if (location.pathname.includes(`/${tenantId}/callscreen`)) return;

    if (Notification.permission !== "granted") Notification.requestPermission();

    const message =
      currentCall?.direction === "INBOUND"
        ? "着信中です"
        : "トスアップされました";

    new Notification(message, {
      body: "クリックして通話画面へ移動",
      icon: callIncomingImage,
      requireInteraction: true,
    }).onclick = () => {
      window.focus();
    };

    console.log("move to callscreen (default)");
    navigate(`/${tenantId}/callscreen`);
  }, [twilioCall, tenantId, callState, navigate, currentCall]);

  // 離席通知
  useEffect(() => {
    if (callState !== "AWAY") return;

    if (user.assignedCallBatchIds.length > 0) {
      dispatch(
        setSnackbar({
          text: "あなたは現在離席中です。再開する場合は「着席」を押してください。",
          severity: "info",
          open: true,
          autoHideDuration: 30000,
        }),
      );
    } else {
      dispatch(
        setSnackbar({
          text: "あなたは現在着信一時停止中です。再開する場合は「着信許可」を押してください。",
          severity: "info",
          open: true,
          autoHideDuration: 30000,
        }),
      );
    }
  }, [callState]);

  useEffect(() => {
    console.log({
      message: "Twilio Device を初期化する UseEffect",
      callDevice,
    });

    if (callDevice) {
      console.log({
        message: "Twilio Device が既に初期化されているため、初期化をスキップ",
      });
      if (
        twilioToken &&
        twilioToken !== callDevice.token &&
        callDevice.state === Device.State.Registered
      ) {
        console.log({
          message: "Twilio Token が更新されているため、更新する",
        });
        callDevice.updateToken(twilioToken);
      }
      return;
    }

    if (!twilioToken) {
      console.log({
        message: "Twilio Token が取得できていないため、取得を試みる",
      });
      dispatch(getTwilioToken());
      return;
    }

    const device = new Device(twilioToken, {
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
      sounds: {
        incoming: silentAudioPath,
      },
    });
    dispatch(setCallDevice(device));
    console.log({
      message: "device initializing",
      device,
    });

    device.on("incoming", (call: Call) => {
      console.log({
        message: "incoming call",
        call,
        callId: call.customParameters.get("callId"),
        parentCallSid: call.parameters["ParentCallSid"],
        callSid: call.parameters["CallSid"],
      });

      call.on("accept", (call: Call) => {
        console.log({
          message: "call accepted",
          call,
          callId: call.customParameters.get("callId"),
          parentCallSid: call.parameters["ParentCallSid"],
          callSid: call.parameters["CallSid"],
        });
        const callSid = call.customParameters.get("callId");
        dispatch(setTwilioCall(call));
        dispatch(setCurrentCall({ callId: callSid }));


        console.log("Twilioでのトスアップ通知",{
          "user.id": user.id,
          "status": "IN_CALL",
          "callId": callSid,
        });

        // ユーザーのステータスを更新
        dispatch(setUserStatus({
          status: "IN_CALL",
        }));
        // ユーザーのコール情報を更新
        dispatch(
          setCurrentCall({
            callId: callSid,
          }),
        );
      });
      call.on("disconnect", () => {
        console.log({
          message: "call disconnected",
        });
      });
      call.on("error", (error) => {
        console.error({
          message: "call error",
          error,
        });
      });
      call.on("cancel", () => {
        console.log({
          message: "call canceled",
        });
      });

      call.accept();
      call.mute(true);
    });
    device.on("destroyed", () => {
      console.log({
        message: "device destroyed",
      });
    });
    device.on("error", (twilioError, call) => {
      console.error({
        message: "device error",
        twilioError,
        call,
      });
    });
    device.on("registered", () => {
      console.log({
        message: "device registered",
      });
    });
    device.on("registering", () => {
      console.log({
        message: "device registering",
      });
    });
    device.on("tokenWillExpire", () => {
      console.log({
        message: "device token will expire",
      });
      dispatch(getTwilioToken());
    });
    device.on("unregistered", () => {
      console.log({
        message: "device unregistered",
      });
    });

    device.register();
    console.log({ message: "device registered" });

    console.log("call device initialized");
  }, [callDevice, twilioToken]);

  const handleLeaveClick = useCallback(
    async (status: boolean) => {
      try {
        const res = await request({
          httpMethod: "post",
          path: "/users/{userId}/away",
          params: {
            paths: {
              userId: user.id,
            },
            body: {
              away: status,
            },
          },
        });
        switch (res.result) {
          case "success":
            dispatch(
              setSnackbar({
                text: status
                  ? "着信を一時停止しました。着信を受け付けなくなります。"
                  : "着信を再開しました。",
                open: true,
                severity: "success",
              }),
            );
            break;
          default:
            dispatch(
              setSnackbar({
                text: "着信一時停止リクエストを送信する際にエラーが発生しました。",
                open: true,
                severity: "error",
              }),
            );
        }
        dispatch(
          setSnackbar({
            text: status
              ? "着信を一時停止しました。着信を受け付けなくなります。"
              : "着信を再開しました。",
            open: true,
            severity: "success",
          }),
        );
      } catch (e) {
        console.error(e);
        dispatch(
          setSnackbar({
            text: "着信一時停止リクエストを送信する際にエラーが発生しました。",
            open: true,
            severity: "error",
          }),
        );
      }
    },
    [user.assignedCallIds.length, user.id],
  );

  useEffect(() => {
    dispatch({ type: "socket/connect" });
  }, []);

  const handleSnackbarClose = () => {
    dispatch(setSnackbar({ open: false }));
  };

  return (
    <>
      {!waitUserFetch || isSignedIn ? (
        <Box display="flex" pt={hideAppBar ? "0" : "64px"}>
          <CssBaseline />
          {hideAppBar ? (
            ""
          ) : (
            <AppBar
              sx={{
                top: 0,
                width: "100%",
                ml: 0,
                background: "#FFFFFF",
                zIndex: 300,
                boxShadow: "none",
              }}
            >
              <Toolbar
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
                <Box height={32}>
                  <img
                    src={teleapoAiLogo}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  ></img>
                </Box>

                {isSignedIn && (
                  <>
                    <Popover
                      open={
                        Notification.permission !== "granted" &&
                        openNotificationRequestModal
                      }
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                      <Stack p={2} gap={2} fontSize="0.85rem">
                        {Notification.permission === "denied" ? (
                          <>
                            <Typography color="error" fontSize="0.85rem">
                              ブラウザ側で通知がブロックされています。
                            </Typography>
                            <Link
                              href="https://support.google.com/chrome/answer/3220216?hl=ja&co=GENIE.Platform%3DDesktop#zippy=%2C%E9%80%9A%E7%9F%A5%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E5%95%8F%E9%A1%8C%E3%82%92%E8%A7%A3%E6%B1%BA%E3%81%99%E3%82%8B:~:text=%E9%80%9A%E7%9F%A5%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E5%95%8F%E9%A1%8C,%E6%9C%89%E5%8A%B9%E3%81%AB%E3%81%97%E3%81%BE%E3%81%99%E3%80%82"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              通知に関する問題を解決する方法
                            </Link>
                          </>
                        ) : (
                          <Typography fontSize="0.85rem">
                            トスアップ通知が無効になっています。
                          </Typography>
                        )}
                        <Stack direction="row" gap={2} justifyContent="end">
                          <Button
                            onClick={() =>
                              setOpenNotificationRequestModal(false)
                            }
                          >
                            閉じる
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => Notification.requestPermission()}
                          >
                            有効化
                          </Button>
                        </Stack>
                      </Stack>
                    </Popover>
                  </>
                )}

                <Divider></Divider>

                {isSignedIn && (
                  <Stack
                    direction="row"
                    gap={2}
                    sx={{ marginLeft: "auto", marginRight: 2 }}
                  >
                    <Button
                      id="demo-customized-button"
                      aria-controls="demo-customized-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                      color={user.status === "AWAY" ? "error" : "primary"}
                      disabled={user.assignedCallBatchIds.length > 0}
                    >
                      <Stack direction="row" gap={1}>
                        <Typography>
                          {(user.assignedCallBatchIds.length > 0 && (
                            <Stack direction="row" gap={1}>
                              <PhoneOutlined />
                              <Typography>コールプロセス参加中</Typography>
                            </Stack>
                          )) ||
                            (user.status === "AWAY" && (
                              <Stack direction="row" gap={1}>
                                <DoNotDisturbOutlined />
                                <Typography>着信一時停止</Typography>
                              </Stack>
                            )) ||
                            (user.status === "AWAY_REQUESTED" && (
                              <Stack direction="row" gap={1}>
                                <PhoneCallbackOutlined />
                                <Typography>{`着信一時停止リクエスト中`}</Typography>
                              </Stack>
                            )) || (
                              <Stack direction="row" gap={1}>
                                <PhoneCallbackOutlined />
                                <Typography>着信許可</Typography>
                              </Stack>
                            )}
                        </Typography>
                      </Stack>
                    </Button>
                    <Menu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleLeaveClick(false)}
                        disableRipple
                      >
                        <PhoneCallbackOutlined />
                        着信許可
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleLeaveClick(true)}
                        disableRipple
                      >
                        <DoNotDisturbOutlined />
                        着信一時停止
                      </MenuItem>
                    </Menu>
                  </Stack>
                )}

                <Stack direction="row" gap={2}>
                  <Avatar></Avatar>
                  <Stack justifyContent="center">
                    <Typography sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
                      {name}
                    </Typography>
                  </Stack>
                </Stack>
              </Toolbar>
            </AppBar>
          )}
          {hideDrawer ? (
            ""
          ) : (
            <DSDrawer
              onClose={handleDrawerClose}
              onTransitionEnd={handleDrawerTransitionEnd}
              onSignOutClick={handleSignOutClick}
              mobileOpen={mobileOpen}
            ></DSDrawer>
          )}
          <Suspense
            fallback={
              <Container sx={{ p: 4, textAlign: "center" }}>
                <LoadingBackdrop loading></LoadingBackdrop>
              </Container>
            }
          >
            <Outlet />
          </Suspense>

          <LoadingBackdrop
            loading={loadingBackdrop || aiCallLoadingBackdrop}
          ></LoadingBackdrop>
          <Snackbar
            open={snackbar.open}
            onClose={handleSnackbarClose}
            autoHideDuration={snackbar.autoHideDuration}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbar.severity}
              variant={snackbar.variant ? snackbar.variant : undefined}
              sx={{ width: "100%" }}
            >
              {snackbar.text}
            </Alert>
          </Snackbar>

          <Snackbar open={missedManualInboundCallOn !== null}>
            <Alert
              severity="info"
              variant="filled"
              sx={{ width: "100%" }}
              onClose={() => dispatch(setMissedManualInboundCallOn(false))}
            >
              着信がありました。(時刻:{" "}
              {missedManualInboundCallOn &&
                dayjs(missedManualInboundCallOn.toDate()).format(
                  "YYYY/MM/DD HH:mm:ss",
                )}
              )
            </Alert>
          </Snackbar>
        </Box>
      ) : (
        <LoadingBackdrop loading={true}></LoadingBackdrop>
      )}
    </>
  );
};

export default Layout;
