import { request } from "@/models/telai-backend/client";

const deleteCallListItems = async (contactIds: string[]): Promise<string[]> => {
  const results: Array<string | null> = await Promise.all(contactIds.map(async id => {
    const res = await request({
      path: "/contacts/{contactId}",
      httpMethod: "delete",
      params: {
        paths: { contactId: id },
      },
    })
    if(res.error)
      return null
    console.log(res)
    return id
  }))
  const deletedIds = results.filter(id => Boolean(id))

  return deletedIds
}

export default deleteCallListItems