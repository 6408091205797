import { CallListItem } from "@/models/CallList";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, IconButton, MenuItem } from "@mui/material";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridInputRowSelectionModel,
  GridRowSelectionModel,
  GridSortModel,
  jaJP,
  useGridApiRef,
} from "@mui/x-data-grid";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import CallListTablePagination from "./CallListTablePagination";

type Props = {
  callListId: string;
  callListItems: CallListItem[];
  rowCount?: number;
  onChangeOptionalInfoOrder?: (columns: string[]) => void;
  rowSelectionModel?: GridInputRowSelectionModel;
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails,
  ) => void;
  checkboxSelection?: boolean;
  showCallResults?: boolean;
  showCallAICallPage?: boolean;
  editMode?: boolean;
  optionalInfoOrder?: string[];
  handleChangePage?: (newPage: number) => void;
  handleChangeSort?: (sortModel: GridSortModel) => void;
  placeholderText?: string;
  hideCheckbox?: boolean;
  pageSize?: number;
  siblingCount?: number;
  autoFocusNeverCalled?: boolean
}

const CallListTable = React.memo(
  ({
    callListId,
    callListItems,
    onChangeOptionalInfoOrder,
    rowSelectionModel,
    rowCount,
    onRowSelectionModelChange,
    checkboxSelection = false,
    showCallResults = false,
    showCallAICallPage = false,
    editMode = false,
    optionalInfoOrder,
    handleChangePage,
    handleChangeSort,
    placeholderText,
    hideCheckbox,
    pageSize = 20,
    siblingCount = 1,
    autoFocusNeverCalled = false,
  }: Props) => {
    const callResultLabels = useSelector(
      (state: RootState) => state.company.config.customCallResultLabels,
    );
    const apiRef = useGridApiRef();
    const prevToolbarScroller = useRef(null);
    const toolbarScroller = useRef(null);
    const dataGridScroller = useRef(null);
    const dataGridScrollerParent = useRef(null);
    const prevCallListId = useRef("");
    const prevCallListLength = useRef(0);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [displayPage, setDisplayPage] = useState(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);

    const scrollToNeverCalledItem = useCallback(() => {
      if(prevCallListId.current !== callListId || prevCallListLength.current !== callListItems.length) {
        const nextNeverCalledItemIndex = callListItems
          .filter(item => displayPage * pageSize <= item.index &&  item.index < (displayPage + 1) * pageSize)
          .findIndex(
            (item) => item.lastCallResult === "NEVER_CALLED",
          );
        apiRef.current.setPaginationModel({ page: 0, pageSize });
        prevCallListId.current = callListId;
        prevCallListLength.current = callListItems.length;

        console.log({ callListItems, rowCount, pageSize, displayPage, nextNeverCalledItemIndex });
        
        // 現在のページでNEVER_CALLEDの項目がない場合、(あれば)次のページに移動
        if (
          (nextNeverCalledItemIndex === -1 && displayPage < Math.floor(rowCount / pageSize)) ||
          (nextNeverCalledItemIndex !== -1 && (displayPage + 1) * pageSize < nextNeverCalledItemIndex)
        ) {
          console.log("handleChangePage", displayPage + 1)
          apiRef.current.setPaginationModel({ page: displayPage + 1, pageSize });
        }

        // NEVER_CALLEDの項目があれば、その項目へスクロール、項目をハイライト
        if(0 < nextNeverCalledItemIndex && apiRef?.current) {
          setTimeout(() => {
            apiRef.current.scrollToIndexes({
              rowIndex: nextNeverCalledItemIndex,
              colIndex: 0
            })
            console.log("scrollToIndexes", nextNeverCalledItemIndex)
          }, 300);    // 即座に呼び出すとスクロールしないため300ms置く
        }
      }
    }, [apiRef, callListId, callListItems, displayPage, pageSize, rowCount])

    // 一番インデックスが小さい未コールの項目へ自動でスクロール
    useEffect(() => {
      if(!callListItems || !callListItems.length || !autoFocusNeverCalled)
        return;
      console.log("a")
      // コールリストが変更された時か次のページをフェッチした際にNEVER_CALLEDを走査
      scrollToNeverCalledItem()
    }, [autoFocusNeverCalled, callListItems, scrollToNeverCalledItem]);

    // 全てのコールリストで表示するカラム
    const defaultColumns = useMemo(() => {
      const cols: GridColDef[] = [
        {
          field: "index",
          headerName: "番号",
          width: 56
        },
        {
          field: "companyName",
          headerName: "会社名",
          width: 200,
          editable: true,
        },
        {
          field: "phoneNumber",
          headerName: "電話番号",
          width: 120,
          editable: true,
        },
      ];

      // コール結果を表示する場合
      if (showCallResults) {
        cols.push({
          field: "lastCallResult",
          headerName: "コール結果",
          width: 140,
          editable: false,
        });
      } else if (showCallAICallPage) {
        cols.splice(2, 0, {
          field: "lastCallResult",
          headerName: "コール結果",
          width: 120,
          editable: false,
        });
      }

      return cols;
    }, [showCallResults, showCallAICallPage]);

    // callListItemsとcolumnsの同期
    useEffect(() => {
      if (!callListItems) return;

      if (optionalInfoOrder?.length) {
        const optionalColumns = optionalInfoOrder.map((key) => ({
          field: key,
          headerName: key,
          width: 120,
          editable: true,
          sortable: false, // firestoreにindexがないためソート不可
        }));
        setColumns(defaultColumns.concat(optionalColumns));
        return;
      }

      if (callListItems[0] && callListItems[0].optionalInfo) {
        const optionalColumns = Object.keys(
          callListItems[0].optionalInfo,
        ).map((key) => ({
          field: key,
          headerName: key,
          width: 120,
          editable: true,
        }));
        setColumns(defaultColumns.concat(optionalColumns));
      } else {
        setColumns(defaultColumns);
      }
    }, [callListItems, defaultColumns, JSON.stringify(optionalInfoOrder), setColumns]);

    const rows = useMemo(
      () =>
        callListItems
        .filter(item => displayPage * pageSize <= item.index &&  item.index < (displayPage + 1) * pageSize)
        .map((item) => {
          // console.log("displayPage", displayPage, item);
          const defaultRow = {
            id: item.index + 1,
            index: item.index + 1,
            companyName: item.companyName,
            phoneNumber: item.phoneNumber.replace(/^\+81/, '0'),
            lastCallResult:
              callResultLabels[item.lastCallResult] ||
              item.lastCallResult ||
              "コール結果未登録",
          };

          if (item.optionalInfo) {
            return {
              ...defaultRow,
              ...item.optionalInfo,
            };
          }
          return defaultRow;
        }),
      [callResultLabels, callListItems, displayPage, pageSize],
    );

    // columnの入れ替え
    const moveColumn = useCallback(
      (oldIndex: number, newIndex: number) => {
        const newColumns = [...columns];
        const tmp = newColumns[oldIndex];
        newColumns[oldIndex] = newColumns[newIndex];
        newColumns[newIndex] = tmp;
        setColumns(newColumns);
      },
      [columns],
    );

    useEffect(() => {
      if (onChangeOptionalInfoOrder)
        onChangeOptionalInfoOrder(
          columns
            .filter(
              (column) =>
                ![
                  "index",
                  "id",
                  "lastCallResult",
                  "companyName",
                  "phoneNumber",
                ].includes(column.field),
            )
            .map((column) => column.field),
        );
    }, [columns]);

    // カラム入れ替え用のツールバー
    const customToolbar = useCallback(() => {
      return (
        <Box
          display="flex"
          maxWidth="100%"
          ref={toolbarScroller}
          mb={-1}
          mt={1}
          sx={{
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            scrollbarWidth: "none",
          }}
        >
          {checkboxSelection && <Box minWidth={50} maxWidth={50}></Box>}
          {editMode &&
            columns.map((column, k) => (
              <Box
                minWidth={column.width}
                maxWidth={column.maxWidth || column.width}
                key={column.field}
              >
                {k > defaultColumns.length - 1 && (
                  <>
                    <IconButton
                      size="small"
                      disabled={k === defaultColumns.length}
                      onClick={() => moveColumn(k, k - 1)}
                    >
                      <KeyboardArrowLeft></KeyboardArrowLeft>
                    </IconButton>
                    <IconButton
                      size="small"
                      disabled={k === columns.length - 1}
                      onClick={() => moveColumn(k, k + 1)}
                    >
                      <KeyboardArrowRight></KeyboardArrowRight>
                    </IconButton>
                  </>
                )}
              </Box>
            ))}
        </Box>
      );
    }, [columns, moveColumn, toolbarScroller, editMode]);

    // DataGridとToolbarのスクロール同期
    useEffect(() => {
      if (!dataGridScrollerParent.current || !toolbarScroller.current) return;
      dataGridScroller.current = dataGridScrollerParent.current.querySelector(
        ".MuiDataGrid-virtualScroller",
      );

      // toolbarのインスタンスが変わった時 (columnsが更新されて再レンダリングされた時) に
      // 再度 data grid のスクロールバーと同期する
      if (
        prevToolbarScroller.current &&
        prevToolbarScroller.current !== toolbarScroller.current
      ) {
        // console.log(prevToolbarScroller.current.scrollLeft, dataGridScroller.current.scrollLeft)
        toolbarScroller.current.scrollLeft =
          dataGridScroller.current.scrollLeft;
      }

      // 同期用の関数
      const syncScroll1 = () =>
        (dataGridScroller.current.scrollLeft =
          toolbarScroller.current.scrollLeft);
      const syncScroll2 = () =>
        (toolbarScroller.current.scrollLeft =
          dataGridScroller.current.scrollLeft);
      toolbarScroller.current.addEventListener("scroll", syncScroll1);
      dataGridScroller.current.addEventListener("scroll", syncScroll2);

      prevToolbarScroller.current = toolbarScroller.current;

      return () => {
        toolbarScroller.current?.removeEventListener("scroll", syncScroll1);
        dataGridScroller.current?.removeEventListener("scroll", syncScroll2);
      };
    }, [dataGridScrollerParent.current, toolbarScroller.current]);

    return (
      <DataGrid
        ref={dataGridScrollerParent}
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: pageSize },
          },
        }}
        checkboxSelection={checkboxSelection}
        pageSizeOptions={[pageSize]}
        rowHeight={36}
        rowSelectionModel={rowSelectionModel}
        sx={{
          bgcolor: "#fff",
          mt: editMode ? 2 : 0,
          "& .MuiDataGrid-cellCheckbox": {
            display: hideCheckbox ? "none" : "inline-block",
          },
          "& .MuiDataGrid-columnHeader:first-of-type": {
            display:
              hideCheckbox && checkboxSelection ? "none" : "inline-block",
          },
          "& .MuiDataGrid-virtualScroller": {
            scrollbarColor: "#ddd #f6f6f6",
            scrollBehavior: "smooth",
          },
          "& .MuiTablePagination-selectLabel": {
            display: { xs: "none", md: "none", lg: "none", xl: "none" },
          },
          "& .MuiTablePagination-select": {
            display: { xs: "none", md: "none", lg: "none", xl: "none" },
          },
          "& .MuiTablePagination-selectIcon": {
            display: { xs: "none", md: "none", lg: "none", xl: "none" },
          },
        }}
        rowCount={handleChangePage ? rowCount : rows.length}
        onRowSelectionModelChange={onRowSelectionModelChange || (() => {})}
        editMode={"row"}
        slots={{
          toolbar: customToolbar,
          noRowsOverlay: () => (
            <Box
              px={4}
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              ({placeholderText || "コールリスト未選択"})
            </Box>
          ),
          pagination: () =>
            <CallListTablePagination
              page={displayPage + 1}
              count={Math.ceil(rowCount / pageSize)}
              onChange={(page) => {
                handleChangePage(page - 1)
                setDisplayPage(page - 1)
              }}
              siblingCount={siblingCount}
              selectItems={
                (Array.from({ length: Math.ceil(rowCount / pageSize) })).map((_, i) => 
                  <MenuItem value={i + 1}>
                    {i + 1}
                  </MenuItem>
                )
              }
            ></CallListTablePagination>
        }}
        paginationMode={handleChangePage ? "server" : "client"}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        onPaginationModelChange={async (paginationModel) => {
          if (handleChangePage) await handleChangePage(paginationModel.page);
          setDisplayPage(paginationModel.page);
        }}
        onSortModelChange={async (sortModel) => {
          if (handleChangeSort) await handleChangeSort(sortModel);
          setSortModel(sortModel);
        }}
        sortModel={sortModel}
        sortingOrder={["asc", "desc"]}
      ></DataGrid>
    );
  },
);

export default CallListTable;
