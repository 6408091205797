import {useEffect, useState } from "react";
import { Box, Button, Container, Snackbar, TextField, Typography } from "@mui/material";
import dialShiftLogo from "@/assets/dialshift_logo.svg";
import { isValidEmail } from "@/utils/isValidEmail";
import { auth } from "@/google/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import { RootState } from "@/store/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SetPassword = () => {
  // ログイン中かどうか
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const [tenantId, setTenantId] = useState('');
  const [helperText, setHelperText] = useState("");
  const [snackbarState, setSnackbarState] = useState({ open: false, message: '', severity: 'info' });
  const [emailError, setEmailError] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  // ログイン中の場合はホーム画面に遷移
  useEffect(() => {
    if(user.id !== '') {
      console.log('User is already logged in', user);
      navigate(`/${user.tenantId}/`);
    }
  }, [user]);

  useEffect(() => {
    if (email && !isValidEmail(email))
      setHelperText("メールアドレスの形式で入力してください");
    else setHelperText("");
  }, [email]);

  const SubmitEmail = async() => {
    auth.tenantId = tenantId;
    await sendPasswordResetEmail(auth, email).then(() => {
      setSnackbarState({ open: true, message: 'パスワード再設定リンクをメールに送信しました。', severity: 'success' });
      resetForm();
      setEmailSuccess(true);
    }).catch((error) => {
      console.error(error);
      setSnackbarState({ open: true, message: 'メールの送信に失敗しました。', severity: 'error' });
      setEmailError(true);
    });
  }
  
  const resetForm = () => {
    setEmail("");
    setTenantId("");
    setHelperText("");
    setEmailError(false);
  }

  const handleCloseSnackbar = () => {
    setSnackbarState({ open: false, message: '', severity: 'info' });
    setEmailError(false)
    setEmailSuccess(false)
  };



  return (
    <Container maxWidth="md" sx={{ pt: 12 }}>
      <Box pb={3}>
        <img src={dialShiftLogo} style={{ minWidth: 160 }}></img>
        </Box>
          <Box
              sx={{
              display: "flex",
              flexDirection: "column",
              mx: "auto",
              p: 2,
              px: 4,
              bgcolor: "#ffffff",
              borderRadius: 1,
              }}
              maxWidth={600}
          >
            <Typography variant="h4" mb={4} fontWeight={600} color="#1C75B5">
              パスワード設定
            </Typography>
            <Typography sx={{ mb: 4 }}>
              登録されているメールアドレスへ、パスワード設定メールを送信します。<br></br>
              メール内容をご確認の上、新しいパスワードを登録してください。
            </Typography>
              
            <TextField
              label="テナントID"
              value={tenantId}
              size="small"
              sx={{ mb: 5, mx:15 }}
              onChange={(e) => setTenantId(e.target.value)}
            ></TextField>
            {/* メールアドレス */}
            <TextField
              label="メールアドレス"
              value={email}
              error={Boolean(helperText)}
              helperText={helperText}
              type="email"
              size="small"
              sx={{ mb: 2, mx:15 }}
              onChange={(e) => setEmail(e.target.value)}
            ></TextField>
            <Button
              disabled={!tenantId || !email}
              variant="contained"
              sx={{ mt:3, mx:20 }}
              onClick={SubmitEmail}
            >
              送信
            </Button>
            {emailError && (
              <Typography sx={{ mt: 3, color: 'red' }}>
                メールの送信に失敗しました。<br></br>テナントID、メールアドレスを確認しもう一度お試しください。
              </Typography>
            )}
            {emailSuccess && (
              <Typography sx={{ mt: 3 }}>
                メールを送信しました。<br></br>メール内のリンクをクリックしてパスワードを設定してください。
              </Typography>
            )}
            <Snackbar
              open={snackbarState.open}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              message={snackbarState.message}
              security={snackbarState.severity}
            />
        </Box>
    </Container>
  );
};

export default SetPassword;

